/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
.container {
    margin-left: 32px !important;
    margin-right: 32px !important;
    padding-left: -15px; 
    padding-right: 0px;
    /* Overrides the above styles on screens larger than 36em */
}

